export function initContactForm() {
  $('#contactFormSendBtn').on('click', handleClick);

  $('#contactForm').on('ajax:success', success);
  $('#contactForm').on('ajax:error', error);
}

function success() {
  $('#contactModal').modal('hide');
  alert('Thank you, your message has been sent.');
}

function error() {
  alert("We're sorry, your message could not be sent. Please try again later.");
}

function handleClick(e) {
  e.preventDefault();
  grecaptcha.ready(function() {
    grecaptcha.execute(window.RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
      let input = $('<input>').attr({type: 'hidden', value: token, name: 'recaptcha_token'});
      $('#contactForm').append(input);
      $('#contactForm')[0].requestSubmit();
    });
  });
}
