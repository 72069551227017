require("@rails/ujs").start()

import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

import "bootstrap/dist/js/bootstrap"
import {initContactForm} from './contact_form'


$(function(){ 
  initContactForm();
});